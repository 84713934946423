import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Modal,
  Card,
  Form,
  Navbar,
  Spinner,
} from "react-bootstrap";
import Button from "../../../../../components/common/Button";
import Input from "../../../../../components/common/Input";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateAddressType } from "./types/CreateAddressType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { addressTypes } from "../../../../constants/constants";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import { createAddressAction } from "../redux/action/createAddressAction";
import { getAppAction } from "../redux/action/getAppAction";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import { getGstAddressAction } from "../redux/action/getGstAddressAction";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import TextArea from "../../../../../components/common/Textarea";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  GSTIN_REGEX,
  MULTIPLE_EMAILS_REGEX,
  NON_INDIAN_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import { getAddressAction } from "../redux/action/getAddressAction";

const OpsAddAddressPage: React.FC = () => {
  let { appId, addressType } = useParams();
  let customerId = Number(appId);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateAddressType>({
    shouldUnregister: true,
  });

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );

  const counterParty = getAppSelector.is_counterparty;
  const isIndia = getAppSelector.business_registration_country_id === 239;

  const getGstAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getGstAddress.data
  );

  const getAllAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress
  );

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  const [showSpinner, setShowSpinner] = useState(false);
  const [countryId, setCountryId] = useState<number>(0);
  const isdCode = watch("phone_country_code");
  const phone = watch("phone");
  const isCountryIndia = Number(watch("country_id")) === 239;
  const [disableBillingGSTIN, setDisableBillingGSTIN] = useState(false);
  const [disableCopyGSTIN, setDisableCopyGSTIN] = useState(false);
  const handleAddressDetailsSubmit: SubmitHandler<CreateAddressType> = (
    addressDetails
  ) => {
    // console.log("Address Details", addressDetails);
    const addressDetailsData = {
      ...addressDetails,
      customer_id: customerId,
      country_id: Number(addressDetails.country_id),
    };
    if (isdCode === "+91" && phone?.length !== 10) {
      alert("Please enter 10 digit phone number");
    } else {
      setShowSpinner(true);
      // console.log(addressDetailsData, "--here");
      dispatch(createAddressAction(addressDetailsData)).then((response) => {
        if (getAppSelector.business_registration_country_id === 239) {
          if (getAppSelector.business_participant_type === "Financier") {
            navigate(`/india/financier/business-details/${customerId}`);
          } else {
            if (
              getAppSelector.business_constitution_type === "LLC" ||
              getAppSelector.business_constitution_type === "LLP" ||
              getAppSelector.business_constitution_type === "PLC"
            ) {
              navigate(`/india/corporate/business-details/${customerId}`);
            } else {
              navigate(`/india/non-corporate/business-details/${customerId}`);
            }
          }
        } else {
          if (getAppSelector.business_participant_type === "Financier") {
            navigate(`/foreign/financier/business-details/${customerId}`);
          } else {
            navigate(`/foreign/business-details/${customerId}`);
          }
        }
        //navigate(`/ops/business-details/${customerId}`);
        setShowSpinner(false);
      });
    }
  };

  // useEffect(() => {
  //   if (createAddressSelector.data && createAddressSelector.data.result) {
  //     navigate(`/ops/business-details/${customerId}`);
  //   } else {
  //     // console.log("Adding Address Failed");
  //   }

  //   // console.log("Create Address Selector", createAddressSelector);
  // }, [createAddressSelector]);

  const selectedAddressTypeCol =
    addressType === "Registered"
      ? "add_address_types_registered"
      : "add_address_types_communication";

  // const selectedAddressType = watch(selectedAddressTypeCol);

  // const isApiAddress =
  //   selectedAddressType == "Copy Register Address" ||
  //   selectedAddressType == "Copy GSTIN address";

  const regAddress = getAllAddressSelector.data.filter(
    (address: any) => address.type === "Registered"
  );

  const regAddressId = regAddress?.[0]?.id;

  useEffect(() => {
    dispatch(getAddressAction(regAddressId)).then((resp) => {
      if (resp.payload) {
        setCountryId(resp.payload.result.country_id);
      }
    });
  }, [regAddressId]);

  const handleAddAddressManually = async () => {
    setValue("line_1", "");
    setValue("line_2", "");
    setValue("city", "");
    setValue("district", "");
    setValue("state", "");
    setValue("pincode", "");
    setValue("email", "");
    setValue("business_billing_preference_email", "");
    setValue("business_communication_preference_email", "");
    setValue("business_billing_gstn", "");
    setValue("phone_country_code", "");
    setValue("phone", "");
    setValue("country_id", null);
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);

    // setValue("country_id", );
  };

  const handleCopyGSTINAddress = async () => {
    setValue("line_1", getGstAddressSelector.result[0].bno);
    setValue("line_2", getGstAddressSelector.result[0].bnm);
    setValue("city", getGstAddressSelector.result[0].st);
    setValue("district", getGstAddressSelector.result[0].loc);
    setValue("state", getGstAddressSelector.result[0].stcd);
    setValue("country_id", 239);
    setValue("pincode", getGstAddressSelector.result[0].pncd);
    setValue("email", "");
    setValue("business_billing_preference_email", "");
    setValue("business_communication_preference_email", "");
    setValue("business_billing_gstn", "");
    setValue("phone_country_code", "");
    setValue("phone", "");
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);
  };

  const handleCopyRegisteredAddress = async () => {
    setValue("line_1", regAddress[0].line_1);
    setValue("line_2", regAddress[0].line_2);
    setValue("city", regAddress[0].city);
    setValue("district", regAddress[0].district);
    setValue("state", regAddress[0].state);
    setValue("country_id", countryId);
    setValue("pincode", regAddress[0].pincode);
    setValue("phone_country_code", regAddress[0].phone_country_code);
    setValue("phone", regAddress[0].phone);
    setValue("email", regAddress[0].email);
    setValue(
      "business_billing_preference_email",
      regAddress[0].business_billing_preference_email
    );
    setValue(
      "business_communication_preference_email",
      regAddress[0].business_communication_preference_email
    );
    setValue("business_billing_gstn", regAddress[0].business_billing_gstn);
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);
  };

  useEffect(() => {
    reset({ type: addressType });
    dispatch(getAllAddressAction(customerId));
  }, []);

  useEffect(() => {
    if (getAppSelector.business_gst) {
      dispatch(getGstAddressAction(getAppSelector.business_gst));
    }
  }, [dispatch, getAppSelector]);

  // useEffect(() => {
  //   if (getAppSelector.business_gst) {
  //     getEnrichApiToken().then((response) => {
  //       // console.log("TOKEN", response);
  //       const token = response.data.data.token;
  //       const orgId = response.data.data.associatedOrgs[0].organisation.id;

  //       getGstDetails(getAppSelector.business_gst, orgId, token).then(
  //         (response) => {
  //           const gstData = JSON.parse(response.data.data);
  //           // console.log("GST DETAILS", gstData);
  //         }
  //       );
  //     });
  //   }
  // }, [getAppSelector]);

  // if (!getGstAddressSelector.data || !getGstAddressSelector.data.data) {
  //   return <Row>Loading...</Row>;
  // }

  const copyGSTIN = async () => {
    // console.log(getAppSelector.business_gst);
    const gstinValue = getAppSelector.business_gst;
    setValue("business_billing_gstn", gstinValue);
    setDisableCopyGSTIN(true);
    setDisableBillingGSTIN(true);
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="ADD ADDRESS DETAILS" />
          </Col>
        </Row>
        <Row>
          <>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Form.Check
                    // label=
                    type="radio"
                    value={"Add Address Manually"}
                    {...register(selectedAddressTypeCol, {
                      required: "Please select an option",
                    })}
                    defaultChecked
                    onClick={() => {
                      handleAddAddressManually();
                    }}
                  />
                  <Card.Text>Add new address</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              {getAllAddressSelector.data.length > 0 && (
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Form.Check
                        label="Copy Registered Address"
                        type="radio"
                        value={"Copy Register Address"}
                        {...register(selectedAddressTypeCol, {
                          required: "Please select an option",
                        })}
                        onClick={() => {
                          handleCopyRegisteredAddress();
                        }}
                      />
                      {`${regAddress[0].line_1} ${regAddress[0].line_2} ${regAddress[0].city} ${regAddress[0].district} ${regAddress[0].state} ${regAddress[0].pincode} ${regAddress[0].country_id.name}`}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col>
              {getGstAddressSelector.result &&
                getGstAddressSelector.result[0] &&
                isIndia && (
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <Form.Check
                          label="Copy GSTIN Address"
                          type="radio"
                          value={"Copy GSTIN address"}
                          {...register(selectedAddressTypeCol, {
                            required: "Please select an option",
                          })}
                          onClick={() => {
                            handleCopyGSTINAddress();
                          }}
                        />
                        {`${getGstAddressSelector.result[0].bno}, ${getGstAddressSelector.result[0].bnm}, ${getGstAddressSelector.result[0].st}, ${getGstAddressSelector.result[0].loc}, ${getGstAddressSelector.result[0].stcd}, ${getGstAddressSelector.result[0].pncd}`}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
            </Col>
          </>
        </Row>

        <Form style={{ width: "119%" }}>
          <Row style={{ marginTop: "30px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Dropdown
                label="Address Type"
                name="type"
                options={addressTypes}
                register={register}
                required
                disabled
                //defaultValue={state.data.business_unit_location}
                //error={errors.business_unit_location?.message}
              />
            </Col>
            <Col sm={5}>
              {getAppSelector.is_counterparty === true && (
                <p style={{ fontSize: "smaller", color: "red" }}>
                  {getAppSelector.business_participant_type === "Seller"
                    ? "Following fields are not applicable for Counter Party Applications"
                    : "For Counter Party Buyer, please fill only communication preferences"}
                </p>
              )}
              <Row>
                <label
                  style={{
                    color: "#214382",
                    marginBottom: "10px",
                  }}
                >
                  Multiple email addresses seperated by <strong>;</strong>
                </label>
              </Row>
              <Row>
                <TextArea
                  label="Send Notifications On Email"
                  name="business_communication_preference_email"
                  register={register}
                  required={
                    getAppSelector.is_counterparty !== true ||
                    (getAppSelector.is_counterparty &&
                      getAppSelector.business_participant_type === "Buyer")
                  }
                  requiredMessage="Email is required"
                  error={
                    errors.business_communication_preference_email?.message
                  }
                  placeholder="Enter Communication Preference Emails seperated by ;"
                  disabled={
                    getAppSelector.is_counterparty === true &&
                    getAppSelector.business_participant_type === "Seller"
                  }
                  regexPattern={MULTIPLE_EMAILS_REGEX}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 1"
                name="line_1"
                register={register}
                required
                //disabled={isApiAddress}
                regexPattern={ADDRESS_REGEX}
                requiredMessage="Address is required"
                error={errors.line_1?.message}
                placeholder="Enter Address Line 1"
              />
            </Col>
            <Col sm={5}>
              {/* <DisplayBox display={getAppSelector.business_participant_type !== "Seller"}
           > */}
              <TextArea
                label="Send VTX Fees Invoice On Email"
                name="business_billing_preference_email"
                register={register}
                required={getAppSelector.is_counterparty !== true}
                requiredMessage="Email is required"
                error={errors.business_billing_preference_email?.message}
                placeholder="Enter Billing Preference Emails seperated by ;"
                disabled={
                  getAppSelector.is_counterparty === true ? true : false
                }
                regexPattern={MULTIPLE_EMAILS_REGEX}
              />
              {/* </DisplayBox> */}
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 2"
                name="line_2"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="Address is required"
                //disabled={isApiAddress}
                error={errors.line_2?.message}
                placeholder="Enter Address Line 2"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              {/* <Input
                label="Address line 3"
                name="line_3"
                register={register}
                required
                requiredMessage="Address is required"
                //error={errors.line_3?.message}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="City"
                name="city"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="city is required"
                //disabled={isApiAddress}
                error={errors.city?.message}
                placeholder="Enter City"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="District/ City Area"
                name="district"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="district is required"
                //disabled={isApiAddress}
                error={errors.district?.message}
                placeholder="Enter District/ City Area"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="State/ County"
                name="state"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="state is required"
                //disabled={isApiAddress}
                error={errors.state?.message}
                placeholder="Enter State/ County"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <CountryDropdown
                label="Country"
                name="country_id"
                register={register}
                required

                //error={errors.type?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Pin/ Zip Code"
                name="pincode"
                register={register}
                required
                regexPattern={isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX}
                requiredMessage="pincode is required"
                //disabled={isApiAddress}
                error={errors.pincode?.message}
                placeholder="Enter Pin/ Zip Code"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Email"
                name="email"
                register={register}
                required
                requiredMessage="email is required"
                error={errors.email?.message}
                regexPattern={EMAIL_REGEX}
                placeholder="Enter Email"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              {/* <IsdCode
                label="Phone/Mobile No"
                name="phone"
                register={register}
                required
                regexPattern={PHONE_REGEX}
                requiredMessage="phone is required"
                error={errors.phone?.message}
              /> */}
              <PhoneInputWithDropdown
                label="Contact No"
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Cellphone"
                error={errors.phone?.message}
                required
                regexPattern={PHONE_REGEX}
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
              {/* <Input
                label="Phone/Mobile No"
                name="phone"
                register={register}
                required
                regexPattern={PHONE_REGEX}
                requiredMessage="phone is required"
                error={errors.phone?.message}
              /> */}
            </Col>
          </Row>
          <Row>
            {getAppSelector.is_counterparty === true && (
              <p style={{ fontSize: "smaller", color: "red" }}>
                Billing GSTN are not applicable for Counter Party Applications
              </p>
            )}
            {isIndia && (
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Billing GSTIN"
                  name="business_billing_gstn"
                  register={register}
                  regexPattern={GSTIN_REGEX}
                  regexMessage="Invalid Format"
                  // required={isIndia && getAppSelector.is_counterparty !== true}
                  // requiredMessage="Billing GSTN is required"
                  // error={errors.business_billing_gstn?.message}
                  placeholder="Enter Billing GSTIN"
                  // defaultValue={`${gstinValue}`}
                  disabled={
                    getAppSelector.is_counterparty === true ||
                    disableBillingGSTIN
                  }
                  // defaultValue={`${copyGSTINValue}`}
                />
                {/* <Col sm={2}>
        <Button label="Copy GSTN" variant="outline-primary"></Button>
      </Col> */}
              </Col>
            )}
            {isIndia && (
              <Col sm={2}>
                <Button
                  label="Copy GSTIN"
                  type="button"
                  variant="outline-primary"
                  onClick={() => {
                    copyGSTIN();
                  }}
                  disabled={
                    getAppSelector.is_counterparty === true || disableCopyGSTIN
                  }
                ></Button>
              </Col>
            )}
          </Row>
          {/* <Button label="Cancel" onClick={closeModal} /> */}
          {/* <Button label="Add Address" onClick={handleAddAddress} /> */}
          <Row>
            <Col md={20} className="text-start">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  label="Save Details"
                  type="submit"
                  onClick={handleSubmit(handleAddressDetailsSubmit)}
                ></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OpsAddAddressPage;
