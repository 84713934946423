import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Container,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";
import SectionHeading from "../../../../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";
import DatePicker from "../../../../../../components/common/DatePicker";
import NavbarVTX from "../../../../../../components/common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import { useEffect } from "react";
import Input from "../../../../../../components/common/Input";
import "./ApplicationListView.css";
import ApplicationStage from "../../../../../../components/common/dropdowns/ApplicationStatus";
import RmNameType from "../../../../../../components/common/dropdowns/RmName";
import { ApplicationStageValues } from "../types/ApplicationStage";
import { getRmName } from "../../../../../apis/listOfValues/getRmName";
import {
  getSessionId,
  getUserRole,
  getUserUid,
  getUsername,
} from "../../../../../../utils/tokenHandlers";
import Button from "../../../../../../components/common/Button";
import { getParticipantAction } from "../../redux/action/getParticipantsAction";
import { updateApplicationAction } from "../../redux/action/updateApplicationListAction";
import {
  MdOutlineDeleteOutline,
  MdOutlineModeEditOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { getApplicationDateSerachApi } from "../../apis/getApplicationDateSearch";
import { classMethod } from "@babel/types";
import CheckBoxes from "../../../../../../components/common/CheckBoxes";
import BusinessName from "../../../../../../components/common/dropdowns/BusinessName";
import RadioButton from "../../../../../../components/common/RadioButton";
import { LogOut } from "../../../../../auth/apis/logout";
import { logoutConCurrLoginAction } from "../../../../../auth/redux/action/logoutConCurrLoginAction";

export type FilterParams = {
  appStage?: string;
  searchData?: string | undefined;
  fromDate?: string;
  tillDate?: string;
  pagenumber?: number;
  application_uid?: string;
  search_any?: string;
  isGuestUser?: boolean;
};

const ApplicationListView: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm<ApplicationStageValues>();
  const dispatch = useDispatch<AppDispatch>();
  const userRole = getUserRole();
  const userName = getUsername();
  const [logoutSpinner, setLogoutSpinner] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [rmData, setRmData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const selectedRmId = watch("employee_name");
  const employeeId = watch("employee_id");
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >(null);
  const [selectBusinessName, setSelectBuisnessName] = useState<string | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [customerId, setCustomerId] = useState<number | null>(null);
  const [stageObjData, setStageObj] = useState("");
  const [dropdownChanged, setDropdownChanged] = useState(false);
  const [rmAssigned, setRMAssigned] = useState(false);
  const [textChanged, setTextChanged] = useState(false);
  const [applicationStageSelected, setApplicationStageSelected] =
    useState(false);

  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [mainSpinner, setMainSpinner] = useState(false);
  const [stageOption, setStageOption] = useState<string>("");
  const [searchDate, setSearchDate] = useState<string>("");
  const [application_Uid, setApplicationUid] = useState("");
  const [viewCkicked, setViewClicked] = useState(false);
  const [defaultText, setDefaultText] = useState(
    "Please Click on Application to Assign RM"
  );
  const [isGuestUser, setIsGuestUser] = useState(false);
  // // console.log(isGuestUser, "guest application");
  const [showSearchSpinner, setShowSearchSpinner] = useState(false);
  const [appStage, setAppStage] = useState("");
  const [arnSearch, setARNSearch] = useState("");
  const [searchAny, setSearchAny] = useState("");
  const [byDateSearch, setByDateSearch] = useState("");
  const [fromDateSearch, setFromDateSearch] = useState("");
  const [tillDateSearch, setTillDateSearch] = useState("");

  const applicationStage = watch("application_stage");
  const rmDate = watch("submitted_date");
  const [selectedRow, setSelectedRow] = useState(0);
  // // console.log("application stage-->", applicationStage);

  const participants = useSelector(
    (state: RootState) => state.opsPpIndApp.getParticipant.data
  );

  useEffect(() => {
    getRmName(getSessionId()).then((response) => {
      setRmData(response.result.result);
    });
  }, []);

  useEffect(() => {
    if (selectedRmId) {
      const rmDetails: any = rmData.filter((rm: any) => rm.id == selectedRmId);
      // console.log(selectedRmId, "here is the id");
      setValue("employee_id", rmDetails[0].barcode);
    }
  }, [selectedRmId]);

  useEffect(() => {
    setValue("search_by_date", undefined);
  }, []);

  const handleLogout = () => {
    setLogoutSpinner(true);
    // console.log("Hello, logout clicked!");
    const userId = getUserUid();
    dispatch(logoutConCurrLoginAction(userId)).then((resp: any) => {
      // console.log("Logout resp--", resp);
      if (userRole === "guest") {
        navigate("/");
      } else if (userRole === "maker" || userRole === "checker") {
        navigate("/ops/login");
      } else {
        <></>;
      }
      setLogoutSpinner(false);
    });
  };

  const handleTableRowClick = (index: any) => {
    setSelectedRow(index);
  };

  const handleRowClick = (
    application_uid: string,
    id: number,
    business_name: string
  ) => {
    setAppId(application_uid);
    setSelectBuisnessName(business_name);
    // console.log(selectBusinessName, "businessname............");
    setCustomerId(id);
    // console.log("seleccted if--->", id);
    setDefaultText("selected");
  };

  const setAppId = async (application_uid: string) => {
    setSelectedApplicationId(application_uid);
  };

  useEffect(() => {
    const date = new Date();
    // console.log(date);
    reset({
      submitted_date: date.toISOString().split("T")[0],
    });
  }, []);
  const arn = watch("application_id");
  // // console.log("arn", arn);

  useEffect(() => {
    if (applicationStage) {
      const stage = applicationStage;
      setAppStage(stage);
      setDropdownChanged(true);
    }
  }, [applicationStage]);

  useEffect(() => {
    if (applicationStage) {
      // changeStage();
      // console.warn(applicationStage);
    }
  }, [applicationStage]);

  useEffect(() => {
    if (applicationStage) {
      setStageOption(`('stage', '=' ,'${applicationStage}',`);
      setFilterArray((prev) => [...prev, stageOption]);
    }
  }, [applicationStage]);
  useEffect(() => {
    if (arn) {
      setApplicationUid(arn);
    }
  }, [arn]);
  // useEffect(() => {
  //   setShowSpinner(true);
  //   dispatch(
  //     getParticipantAction({
  //       appStage: applicationStage,
  //       application_uid: application_Uid,
  //       isGuestUser,
  //     })
  //   ).then((response) => {
  //     setShowSpinner(false);
  //     // console.log("main", response);
  //   });
  // }, [applicationStage, dispatch, application_Uid, isGuestUser]);

  // const changeStage = () => {
  //   dispatch(
  //     getParticipantAction({
  //       appStage: applicationStage,
  //     })
  //   );
  // };

  const handleAssignClick = () => {
    if (selectedApplicationId) {
      // console.log("Assigning application with ID:", selectedApplicationId);
      // console.log("Employee Name:", selectedRmId);
      // console.log("Employee ID:", employeeId);
      // console.log("customer id", customerId);
      // console.log("RM date:", rmDate);
      // Dispatch the update action
      dispatch(
        updateApplicationAction({
          customerId,
          selectedRmId,
          employeeId,
          rmDate,
        })
      ).then((response) => {
        // console.log("in main file-->", response);
        setRMAssigned(true);

        dispatch(
          getParticipantAction({
            appStage: applicationStage,
            application_uid: arnSearch,
            searchData: byDateSearch,
            fromDate: fromDateSearch,
            tillDate: tillDateSearch,
          })
        ).then((resp) => {
          window.alert("RM has been assigned successfully!");
        });
      });

      // changeStage();
    } else {
      // console.log("No application selected for assignment.");
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getParticipantAction({
  //       appStage: applicationStage,
  //     })
  //   );
  // }, [applicationStage, dispatch, rmAssigned]);

  const navigate = useNavigate();

  const handleAddClick = (
    applicationUid: any,
    businessConstitutionType: any,
    businessParticipantType: any,
    business_registration_country_id: { name: any },
    id: number,
    applicationStage: string,
    isGuest: boolean
  ) => {
    // console.log(business_registration_country_id.name, "country...........");
    // console.log(applicationStage, "country...........");
    // console.log(userRole, "country...........");
    // console.log(isGuest, "country...........");

    if ((userRole === "maker" || userRole === "checker") && isGuest === true) {
      navigate(`/verification/business-details/${id}`);
    } else if (
      userRole === "checker" &&
      (applicationStage === "In Form Signing" ||
        applicationStage === "New / Draft" ||
        applicationStage === "Sent back to correction")
    ) {
      navigate(`/verification/business-details/${id}`);
    } else {
      if (applicationStage === "In Form Signing") {
        navigate(`/application-signing/${id}`);
      } else if (
        applicationStage === "In Maker Verification" ||
        applicationStage === "In Checker Verification" ||
        applicationStage === "Sent back to maker" ||
        applicationStage === "In Customer Registration" ||
        applicationStage === "Expired" ||
        applicationStage === "Customer Registered"
      ) {
        navigate(`/verification/business-details/${id}`);
      } else {
        if (userRole === "maker") {
          if (business_registration_country_id.name === "India") {
            if (businessParticipantType === "Financier") {
              navigate(`/india/Financier/business-details/${id}`);
            } else if (
              businessConstitutionType === "partnership" ||
              businessConstitutionType === "proprietorship"
            ) {
              navigate(`/india/non-corporate/business-details/${id}`);
            } else {
              navigate(`/india/corporate/business-details/${id}`);
            }
          } else {
            if (businessParticipantType === "Financier") {
              navigate(`/foreign/Financier/business-details/${id}`);
            } else {
              navigate(`/foreign/business-details/${id}`);
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    // console.log(applicationStage, "here");
    if (applicationStage && applicationStage.trim() !== "") {
      // console.log("hello");
      setMainSpinner(true);
      dispatch(
        getParticipantAction({
          appStage: applicationStage,
        })
      ).then((res: any) => {
        // console.log(res, "-res app stage");
        // setByDateSearch("");
        // setARNSearch("");
        setValue("application_id", "");
        setValue("search_by_date", "");
        setValue("fromdate", "");
        setTillDateSearch("");
        setViewClicked(false);
        setMainSpinner(false);
      });
    } else {
      // console.log("else");
    }
  }, [applicationStage, dispatch]);

  useEffect(() => {
    // // console.log(arn, "here");
    const isViewClicked = localStorage.getItem("viewClicked");
    // console.warn(isViewClicked);
    if (isViewClicked) {
      setViewClicked(true);
    }
  }, []);

  const handleSearchByDateChange = (selectedDate: any) => {
    setByDateSearch(selectedDate);
    // console.log(selectedDate);
  };

  const handleFromDateChange = (selectedDate: any) => {
    setFromDateSearch(selectedDate);
    // console.log(selectedDate, "from date");
  };

  const handleTillDateChange = (selectedDate: any) => {
    if (!fromDateSearch) {
      alert("Please select From Date First");
    } else {
      setTillDateSearch(selectedDate);
      // console.log(selectedDate, "Till date");
    }
  };

  const handleSearch = (value: string) => {
    // const trimmedValue = value.trimEnd();
    const trimmedValue = value.replace(/\s+$/, "");
    setSearchAny(trimmedValue);
    if (trimmedValue.length > 4) {
      setShowSearchSpinner(true);
      dispatch(
        getParticipantAction({
          appStage: applicationStage,
          application_uid: arnSearch,
          search_any: trimmedValue,
          searchData: byDateSearch,
          fromDate: fromDateSearch,
          tillDate: tillDateSearch,
        })
      ).then((res: any) => {
        // console.log(res, "-res filter button");
        if (res.payload.result) {
          setShowSearchSpinner(false);
          setViewClicked(false);
        } else {
          alert("Something went wrong! Please try later");
          setShowSearchSpinner(false);
        }
      });
    }
  };

  const resetForm = () => {
    setByDateSearch("");
    setARNSearch("");
    setFromDateSearch("");
    setTillDateSearch("");
    // setAppStage
    reset();
    setValue("application_stage", "");
  };

  const handleFilterSearch = async () => {
    // console.log(arnSearch, "arn here");
    setShowSearchSpinner(true);
    dispatch(
      getParticipantAction({
        appStage: applicationStage,
        application_uid: arnSearch,
        // search_any: searchAny,
        searchData: byDateSearch,
        fromDate: fromDateSearch,
        tillDate: tillDateSearch,
      })
    ).then((res: any) => {
      // console.log(res, "-res filter button");
      if (res.payload.result) {
        setShowSearchSpinner(false);
        setViewClicked(false);
      } else {
        alert("Something went wrong! Please try later");
        setShowSearchSpinner(false);
      }
    });
  };

  return (
    <>
      <Row>
        <Col className="m-2">
          <NavbarVTX></NavbarVTX>
        </Col>
        <Col
          md={11}
          className="text-end"
          style={{ marginTop: "-5%", color: "#214382" }}
        >
          {userName} as {userRole}
          <span
            style={{ color: "#214382", marginLeft: "2%", cursor: "pointer" }}
            onClick={handleLogout}
          >
            {logoutSpinner ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <>LogOut</>
            )}
          </span>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <SectionHeading text="APPLICATION LIST VIEW" />
          </Col>
        </Row>
        <Row md={12}>
          <Col sm={6} className="mt-2">
            <ApplicationStage
              label="Application Stage"
              name="application_stage"
              register={register}
            />
          </Col>
        </Row>
        {mainSpinner ? (
          <>
            <Row className="mt-4">
              <Col className="text-center">
                <Spinner animation="border" variant="primary" />
                <p>Hold on we are loading the applications...</p>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Form>
              <hr style={{ borderColor: "#888" }} />

              <SectionHeading text="FILTER BY" />
              <Row md={12}>
                <Col sm={6} className="text-center">
                  <Input
                    label={"Search"}
                    name={"search_any"}
                    register={register}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search Any Value"
                  />
                </Col>
              </Row>
              <Row md={12} className="mt-3">
                <Col sm={6} className="text-center">
                  <Input
                    label="Application ID"
                    name="application_id"
                    register={register}
                    placeholder="Search ARN"
                    onChange={(e) => {
                      const trimmedValue = e.target.value.replace(/\s+$/, "");
                      setARNSearch(trimmedValue);
                    }}
                  />
                </Col>
                <Col>
                  <DatePicker
                    label="Search By Date"
                    name="search_by_date"
                    register={register}
                    onChange={(selectedDate) =>
                      handleSearchByDateChange(selectedDate)
                    }
                    isDateOfBirth={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DatePicker
                    label="From Date"
                    name="fromdate"
                    register={register}
                    onChange={(selectedDate) =>
                      handleFromDateChange(selectedDate)
                    }
                    isDateOfBirth={true}
                  />
                </Col>
                <Col>
                  <DatePicker
                    label="Till Date"
                    name="tilldate"
                    register={register}
                    onChange={(selectedDate) =>
                      handleTillDateChange(selectedDate)
                    }
                    isDateOfBirth={true}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                <Col className="text-center">
                  <Button
                    label="Reset"
                    type="button"
                    variant="outline-primary"
                    onClick={() => resetForm()}
                  />
                  {showSearchSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button
                      label={"Search"}
                      type="button"
                      onClick={() => handleFilterSearch()}
                    />
                  )}
                </Col>
              </Row>
              {appStage === "In Maker Verification" && (
                <>
                  <hr style={{ borderColor: "#888" }} />
                  <SectionHeading text="ASSIGN RM" />
                </>
              )}

              <Row md={12} style={{ marginLeft: "8%" }} className="mt-2">
                {/* <Col md={3}> */}

                <Col md={3} className="mt-3">
                  {appStage === "In Maker Verification" && (
                    <RmNameType
                      label="RM Name"
                      name="employee_name"
                      register={register}
                    />
                  )}
                </Col>

                <Col md={3} className="mt-3">
                  {appStage === "In Maker Verification" && (
                    <Input
                      label="RM ID"
                      name="employee_id"
                      register={register}
                      disabled
                    />
                  )}
                </Col>

                <Col md={3} className="mt-3">
                  {appStage === "In Maker Verification" && (
                    <DatePicker
                      label="RM Date"
                      name="submitted_date"
                      register={register}
                      disabled
                    />
                  )}
                </Col>

                <Col md={3} className="mt-2">
                  {appStage === "In Maker Verification" && (
                    <Button
                      label="Assign"
                      type="button"
                      onClick={handleAssignClick}
                    />
                  )}
                </Col>
                {/* </Row>
                </Col> */}
              </Row>
              <Container>
                <Row
                  md={12}
                  className="mt-3"
                  style={{ fontSize: "14px", color: "#214382" }}
                >
                  {defaultText === "selected" ? (
                    <>
                      <Col md={6} className="text-center">
                        {appStage === "In Maker Verification" && (
                          <label>Application Id: {selectedApplicationId}</label>
                        )}
                      </Col>
                      <Col md={6} className="text-center">
                        {appStage === "In Maker Verification" && (
                          <label>Business name: {selectBusinessName}</label>
                        )}
                      </Col>
                    </>
                  ) : (
                    <Col className="text-center" md={12}>
                      {appStage === "In Maker Verification" && (
                        <label>{defaultText}</label>
                      )}
                    </Col>
                  )}
                </Row>
              </Container>
            </Form>
            {!viewCkicked && (
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{ padding: "10%" }}
                className="custom-table mt-2"
              >
                <thead>
                  <tr>
                    <th>ARN</th>
                    <th>BUSINESS NAME</th>
                    <th>COUNTRY</th>
                    <th>CONSTITUTION TYPE</th>
                    <th>ROLE</th>
                    <th>CREATED DATE</th>
                    <th>JOURNEY</th>
                    <th>RM NAME</th>
                    <th>CONTACT PERSON NAME</th>
                    <th>CONTACT PERSON MOBILE</th>
                    <th>CONTACT PERSON EMAIL</th>
                    <th>ACTION</th>

                    <th>AUTHORISED DATE</th>
                    <th>PARTICIPANT ID</th>
                    <th>CNX RESPONSE</th>
                    <th>AUTHORISATION</th>
                    <th>CREATED BY</th>
                    <th>STAGE</th>
                  </tr>
                </thead>
                <tbody>
                  {participants &&
                    participants.map((rowData: any, index: number) => (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => {
                            handleRowClick(
                              rowData.application_uid,
                              rowData.id,
                              rowData.business_name
                            );
                            handleTableRowClick(rowData.id);
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedRow === rowData.id ? "lightblue" : "",
                          }}
                        >
                          <td>{rowData.application_uid}</td>
                          <td>{rowData.business_name}</td>
                          <td>{`${rowData.business_registration_country_id.name}`}</td>
                          <td>{rowData.business_constitution_type}</td>
                          <td>{rowData.business_participant_type}</td>
                          <td>{rowData.create_date}</td>
                          <td>
                            {rowData.is_guest_user === true
                              ? "Guest"
                              : "Operations"}
                          </td>
                          <td>{`${
                            rowData.employee_name.name === null
                              ? ""
                              : rowData.employee_name.name
                          }`}</td>
                          <td>{rowData.customer_contact_name}</td>
                          <td>{`${rowData.customer_contact_mobile_country_code} ${rowData.customer_contact_mobile}`}</td>
                          <td>{rowData.customer_contact_email}</td>
                          <td>
                            <Row>
                              <Col>
                                <>
                                  {userRole === "maker" &&
                                  (applicationStage ===
                                    "In Checker Verification" ||
                                    applicationStage ===
                                      "In Customer Registration" ||
                                    applicationStage ===
                                      "Sent back to correction" ||
                                    applicationStage === "Expired" ||
                                    applicationStage ===
                                      "Customer Registered") ? (
                                    <MdOutlineRemoveRedEye
                                      className="table-actions"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAddClick(
                                          rowData.application_uid,
                                          rowData.business_constitution_type,
                                          rowData.business_participant_type,
                                          rowData.business_registration_country_id,
                                          rowData.id,
                                          rowData.stage,
                                          rowData.is_guest_user
                                        )
                                      }
                                    />
                                  ) : userRole === "checker" &&
                                    (applicationStage ===
                                      "In Maker Verification" ||
                                      applicationStage ===
                                        "In Customer Registration" ||
                                      applicationStage ===
                                        "Sent back to correction" ||
                                      applicationStage === "Expired" ||
                                      applicationStage ===
                                        "Customer Registered" ||
                                      applicationStage === "New / Draft" ||
                                      applicationStage ===
                                        "In Form Signing") ? (
                                    <MdOutlineRemoveRedEye
                                      className="table-actions"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAddClick(
                                          rowData.application_uid,
                                          rowData.business_constitution_type,
                                          rowData.business_participant_type,
                                          rowData.business_registration_country_id,
                                          rowData.id,
                                          rowData.stage,
                                          rowData.is_guest_user
                                        )
                                      }
                                    />
                                  ) : (
                                    <MdOutlineModeEditOutline
                                      className="table-actions"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAddClick(
                                          rowData.application_uid,
                                          rowData.business_constitution_type,
                                          rowData.business_participant_type,
                                          rowData.business_registration_country_id,
                                          rowData.id,
                                          rowData.stage,
                                          rowData.is_guest_user
                                        )
                                      }
                                    />
                                  )}
                                </>
                              </Col>
                            </Row>
                          </td>
                          <td>{rowData.authorise_date}</td>
                          <td>{rowData.participant_number_cnx}</td>
                          <td>{rowData.participant_response_message_cnx}</td>
                          <td>{rowData.participant_reason_cnx}</td>
                          <td>{`${rowData.create_uid.name}`}</td>
                          <td>{rowData.stage}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export default ApplicationListView;
