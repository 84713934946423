// export const EMAIL_REGEX = /^[a-zA-Z0-9._%+]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const SWIFTCODE_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
export const PHONE_REGEX = /^[1-9][0-9]*$/;
export const WEBSITE_REGEX =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9 ]*$/;
export const CKYC_REGEX = /^(\d{14})$/;
export const ALPHABETS_REGEX = /^[a-zA-Z\s ]*$/;
export const PINCODE_REGEX = /^\d{6}$/;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,/&\-.()]*$/;
export const OWNERSHIP_REGEX = /^(0|[1-9][0-9]?|100)(\.[0-9]+)?$/;
export const PARTNERSHIP_REGEX = /^(0|[1-9][0-9]?|100)(\.[0-9]+)?$/;
export const GSTIN_REGEX =
  /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[0-9A-Z]{1}[A-Z0-9]{1}[0-9A-Z]{1}$/;
export const LEI_REGEX = /^[0-9A-Za-z]{20}$/;
export const NON_INDIAN_REGEX = /^[0-9]{3,7}$/;
export const NAME_REGEX = /^[a-zA-Z\s]+$/;
export const MULTIPLE_EMAILS_REGEX = /([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)/;
export const NATURE_ACTIVITY_REGEX = /^(?:[\w., -]){1,255}$/;
export const PAN_REGEX = /^[A-Z]{5}\d{4}[A-Z]$/;
