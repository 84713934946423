import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const logoutConCurrrentLogin = (userId: number) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}user/clear_all_sessions`,
      {
        params: {
          f_uid: userId,
        },
      },
      {
        params: {
          o_ses_id: `${sessionid}`,
        },
      }
    )
    .then((response) => {
      //  // console.log("in api bro-->", guest_user_arn);
      //  // console.log("api response-->", response);
      return response;
    })
    .catch((err) => err);
};
