import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import { Container, FormCheck, Row, Col, Form } from "react-bootstrap";
import Button from "../../../../../components/common/Button";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAppAction } from "../redux/action/getAppAction";
import { useForm } from "react-hook-form";
import { BusinessDetailsType } from "./types/BusinessDetailsType";
import { updateAppAction } from "../redux/action/updateAppAction";
import { updateStageAction } from "../redux/action/updateStageAction";
import { fieldValidationAction } from "../redux/action/fieldValidation";
import { ValidationErrorModal } from "../components/ValidationErrorModal";

const OpsApplicationUndertaking = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  let stage = `move_stage_form_filling_to_signing`;
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );
  let appStage = getAppSelector.stage;

  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({});

  const [validationError, setValidationError] = useState("");
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(getAppAction(customerId)).then((respose) => {
      // console.log(respose);
    });
  }, [dispatch, customerId]);

  const openValidationModal = (validationErr: string) => {
    setValidationError(validationErr);
    setShowValidationModal(true);
  };

  useEffect(() => {
    reset({
      maker_declaration_accepted: getAppSelector.maker_declaration_accepted,
    });
  }, []);

  const checkNavigate = () => {
    dispatch(fieldValidationAction({ customerId, stage })).then((response) => {
      // console.log("Api resp-->", response);
      if (response.payload.error) {
        const validationErr = response.payload.error.data.message;
        // console.warn(validationErr);
        openValidationModal(validationErr);
      } else {
        navigate(`/application-signing/${customerId}`);
      }
    });
  };

  const cpUndertaking = (
    <ul>
      <li>
        I/We hereby declare that all information furnished by me/ us above in
        this referral form are true and correct to the best of my/ our knowledge
        and belief.
      </li>
      <li>
        I/We hereby declare that in the above referral form we have not referred
        our related party as our Counter Party for the transaction on VTX, also
        we shall keep VTX updated on the related party status of all our Counter
        Parties.
      </li>
      <li>
        I/We hereby understand that this Referral Form is meant to enable a
        counter party on the VTX.
      </li>
      <li>
        I/We hereby confirm that VTX Directors, employees and agents shall not
        be liable in any manner for any claims arising whatsoever on account of
        rejection of any Counter party application due to any non-compliance.
      </li>
    </ul>
  );

  const buyerSellerUndertaking = (
    <ul>
      <li>
        I / We undertake this declaration on behalf of all the individuals
        mentioned in this application and for their consent on the same, they
        all have affixed their signatures on their KYC documents enclosed with
        this form.
      </li>
      <li>
        I/We understand and thereby authorise VTX to use information provided in
        the application form, to fetch entity and individuals' details from
        third-party service providers. VTX intends to use the information
        obtained from any third-party service providers solely for aiding in the
        onboarding process on VTX's platform for the purpose of availing VTX's
        ITFS services.
      </li>
      <li>
        I/We agree and accept VTX's Privacy Policy and all other Terms and
        Conditions as applicable.
      </li>
      <li>
        I/We understand VTX will be uploading/downloading KYC information of the
        entity on CKYC registry. I/We hereby consent to receiving information
        from Central KYC Registry through SMS/ Email on the registered mobile
        number/email address. It is understood by me/us that the consent given
        by the legal entity shall be considered as deemed consent by all the
        key/ authorised individuals of the legal entity (key person, authorised
        officials, administrators, beneficial owners etc.).
      </li>
      <li>
        I/We hereby consent to receive information from Central KYC Registry
        through SMS/ Email on the registered mobile number/email address
        provided by me/us.
      </li>
      <li>
        I/We confirm that there are no arrears of statutory dues, and no
        government enquiries/ proceedings/ prosecution/ legal action are
        pending/initiated against the applicant entity/unit/promoters/directors/
        partners/proprietor/beneficial owners.
      </li>
      <li>
        I/ We also confirm that neither I/we nor any of the promoters or
        directors or partners or trustees or beneficial owners have at any time
        declared themselves as insolvent.
      </li>
      <li>
        I/We have no objection to VTX/its representatives making necessary
        enquiries/verifications through credit information agencies or carrying
        out visits to any of the unit/location/branch/office of the applicant
        entity, while considering my/our application for registration on VTX.
        I/We undertake to furnish all other information that may be required by
        VTX in connection with my/our application for registration.
      </li>
      <li>
        I/We undertake and confirm that we have provided details of our Working
        Capital Account. Or in case of other accounts, I/We undertake and
        confirm that we don't avail any facility for CC / OD or working capital
        from any Bank / NBFC or other Financial institution.
      </li>
      <li>
        I /We hereby undertake that if any transaction is delayed or not
        effected for reasons of incomplete or incorrect information, I /We shall
        not hold VTX / Financiers registered with VTX responsible.
      </li>
      <li>
        I/We agree to submit this Application form along with Master Agreement
        and other requisite on-boarding documents after affixing our physical
        signature along with the stamp of the entity or shall submit it by
        digitally signing the same.
      </li>
      <li>
        I/We hereby give VTX voluntary consent for authenticating my/our
        identity and address with UIDAI's Aadhaar-based authentication system
        for conducting KYC and e-Sign (if applicable).
      </li>
      <li>
        I/We hereby understand and agree that my/our KYC verification will be
        done by VTX either via Video Customer Identification Process (V-CIP) or
        by contact point verification.
      </li>
      <li>
        I/We understand and agree that the Aadhaar information so collected by
        VTX will be used for Aadhar-based e-signing of documents and/or
        conducting my/our KYC and/or to authenticate my/our credentials.
      </li>
      <li>
        I/We hereby enclose true copies of all the documents. In case any
        documents are found false, tampered/fabricated, or suppressive of any
        material information, I/We am/are aware that I/We may be held liable for
        it and it may lead to de-registration on VTX.
      </li>
      <li>
        I/We hereby authorise VTX, its authorised agents and representatives to
        disclose, share, remit in any form, mode, or manner all/any of the
        information provided by me/us.
      </li>
      <li>
        I/We hereby agree to provide any additional information/documents that
        may be required in connection with this application.
      </li>
      <li>
        I/We hereby declare that the particulars given herein are true, correct
        and complete to the best of my/our knowledge and belief. The documents
        submitted along with the application are genuine, and I/We are not
        making this application for the purpose of contravention of any Act,
        Rules, Regulations, or any Statute or Legislation or any Notifications/
        Directions issued by any governmental or statutory authority from time
        to time.
      </li>
      <li>
        I/We hereby declare that all information furnished by me/us above in
        this Application & Appendix/Annexure/Statements and other
        papers/documents enclosed including all KYC information and documents
        enclosed/uploaded are true and correct to the best of my/our knowledge
        and belief.
      </li>
      <li>
        I/We undertake to inform VTX of any change(s) therein, immediately, to
        facilitate updating of records. In case any of the above information is
        found to be false or untrue or misleading or misrepresenting, I/We
        am/are aware that I/We may be held liable for it and may lead to
        de-registration from VTX.
      </li>
      <li>
        I/We authorize the VTX to act on instructions, communications, or
        documents sent via our official Email ID of Authorised official provided
        herein above. These instructions reflect our internal decisions, and
        I/we will provide necessary documents if needed. I/We accept
        responsibility for any losses due to actions taken based on these
        instructions, understanding email-related risks. VTX may act on these
        instructions without written confirmation. I/We are accountable for
        authenticity, and the VTX is not liable for unauthorized communication.
        I/We acknowledge the platform's record-keeping rights and agree to
        cooperate in resolving technical errors, including Force Majeure
        situations.
      </li>
      <li>
        I/we, affirm that the transaction/s conducted through Vayana
        TradeXchange, both present and future, fully comply with the provisions
        of the Foreign Exchange Management Act-1999. I/we undertake to provide
        all necessary information/documents as required by Vayana TradeXchange
        before any transaction, ensuring compliance with regulatory standards.
        I/we acknowledge that non-compliance may result in transaction refusal,
        and in case of suspected evasion, Vayana TradeXchange reserves the right
        to report the matter to relevant authorities.
      </li>
    </ul>
  );

  const financierUndertaking = (
    <ul>
      <li>
        I / We undertake this declaration on behalf of all the individuals
        mentioned in this application and for their consent on the same, they
        all have affixed their signatures on their KYC documents enclosed with
        this form.
      </li>
      <li>
        I/We understand and thereby authorise VTX to use information provided in
        the application form, to fetch entity and individuals' details from
        third-party service providers. VTX intends to use the information
        obtained from any third-party service providers solely for aiding in the
        onboarding process on VTX's platform for the purpose of availing VTX's
        ITFS services.
      </li>
      <li>
        I/We agree and accept VTX's Privacy Policy and all other Terms and
        Conditions as applicable.
      </li>
      <li>
        I/We understand VTX will be uploading/downloading KYC information of the
        entity on CKYC registry. I/we hereby give our consent to receiving
        information from Central KYC Registry through SMS/Email on the
        registered mobile number/email address. It is understood by me/us that
        the consent given by the legal entity shall be considered as deemed
        consent by all the key/authorised individuals of the legal entity (key
        person, authorised officials, administrators, beneficial owners etc.).
      </li>
      <li>
        I/We hereby consent to receive information from Central KYC Registry
        through SMS/Email on the registered mobile number/email address provided
        by me/us.
      </li>
      <li>
        I/We have no objection to VTX/its representatives making necessary
        enquiries/verifications through credit information agencies.
      </li>
      <li>
        I/We undertake to furnish all other information that may be required by
        VTX in connection with my/our application for registration.
      </li>
      <li>
        I/We hereby undertake that if any transaction is delayed or not effected
        for reasons of incomplete or incorrect information, I/we shall not hold
        VTX / Financiers registered with VTX responsible.
      </li>
      <li>
        I/We agree to submit this Application form along with Master Agreement
        and other requisite onboarding documents after affixing our physical
        signature along with the stamp of the entity or shall submit it by
        digitally signing the same.
      </li>
      <li>
        I/We hereby give VTX voluntary consent for authenticating my/our
        identity and address with UIDAI's Aadhaar-based authentication system
        for conducting KYC and e-Sign (if applicable).
      </li>
      <li>
        I/We hereby understand and agree that my/our KYC verification will be
        done by VTX either via Video Customer Identification Process (V-CIP) or
        by contact point verification.
      </li>
      <li>
        I/We understand and agree that the Aadhaar information so collected by
        VTX will be used for Aadhar-based e-signing of documents and/or
        conducting my/our KYC and/or to authenticate my/our credentials.
      </li>
      <li>
        I/We hereby enclose true copies of all the documents. In case any
        documents are found false, tampered/fabricated, or suppressive of any
        material information, I/We am/are aware that I/We may be held liable for
        it and it may lead to de-registration on VTX.
      </li>
      <li>
        I/We hereby authorise VTX, its authorised agents and representatives to
        disclose, share, remit in any form, mode, or manner all/any of the
        information provided by me/us.
      </li>
      <li>
        I/We hereby agree to provide any additional information/documents that
        may be required in connection with this application.
      </li>
      <li>
        I/We hereby declare that the particulars given herein are true, correct
        and complete to the best of my/our knowledge and belief. The documents
        submitted along with the application are genuine, and I/We are not
        making this application for the purpose of contravention of any Act,
        Rules, Regulations, or any Statute or Legislation or any Notifications/
        Directions issued by any governmental or statutory authority from time
        to time.
      </li>
      <li>
        I/We hereby declare that all information furnished by me/us above in
        this Application & Appendix/Annexure/Statements and other
        papers/documents enclosed including all KYC information and documents
        enclosed/uploaded are true and correct to the best of my/our knowledge
        and belief. I/We undertake to inform VTX of any changes therein,
        immediately, to facilitate updating of records. In case any of the above
        information is found to be false or untrue or misleading or
        misrepresenting, I/We am/are aware that I/We may be held liable for it
        and may lead to de-registration from VTX.
      </li>
      <li>
        I/We authorize the VTX to act on instructions, communications, or
        documents sent via our official Email ID of Authorised official provided
        herein above. These instructions reflect our internal decisions, and
        I/we will provide necessary documents if needed. I/We accept
        responsibility for any losses due to actions taken based on these
        instructions, understanding email-related risks. VTX may act on these
        instructions without written confirmation. I/We are accountable for
        authenticity, and the VTX is not liable for unauthorized communication.
        I/We acknowledge the platform's record-keeping rights and agree to
        cooperate in resolving technical errors, including Force Majeure
        situations.
      </li>
      <li>
        I/we, affirm that the transaction/s conducted through Vayana
        TradeXchange, both present and future, fully comply with the provisions
        of the Foreign Exchange Management Act-1999. I/we undertake to provide
        all necessary information/documents as required by Vayana TradeXchange
        before any transaction, ensuring compliance with regulatory standards.
        I/we acknowledge that non-compliance may result in transaction refusal,
        and in case of suspected evasion, Vayana TradeXchange reserves the right
        to report the matter to relevant authorities.
      </li>
    </ul>
  );

  const handleUndertakingSubmit = (data: any) => {
    if (appStage === "New / Draft" || appStage === "Sent back to correction") {
      const updateCustomerData = {
        ...data,
        // stage: "In Form Signing",
      };

      dispatch(updateAppAction({ customerId, updateCustomerData })).then(
        (response) => {
          // console.log(response);
          checkNavigate();
        }
      );
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="DECLARATION/ CONFIRMATION/ UNDERTAKING" />
          </Col>
        </Row>
        <Form
          onSubmit={handleSubmit(handleUndertakingSubmit)}
          style={{ fontSize: "small" }}
        >
          <input
            type="checkbox"
            {...register("maker_declaration_accepted", {
              required: "Please Accept this before submit",
            })}
            onChange={() => setIsChecked(true)}
            style={{
              transform: "scale(1.5)", // Optional: Increases checkbox size
              accentColor: "#007bff", // Optional: Sets checkbox highlight color
              cursor: "pointer", // Optional: Adds pointer cursor on hover
            }}
          />

          {/* {getAppSelector.is_counterparty && cpUndertaking}
          {getAppSelector.business_participant_type == "Financier" &&
            financierUndertaking}
          {(getAppSelector.business_participant_type == "Buyer" ||
            getAppSelector.business_participant_type == "Seller") &&
            buyerSellerUndertaking} */}

          {getAppSelector.is_counterparty
            ? cpUndertaking
            : getAppSelector.business_participant_type === "Financier"
            ? financierUndertaking
            : getAppSelector.business_participant_type === "Buyer" ||
              getAppSelector.business_participant_type === "Seller"
            ? buyerSellerUndertaking
            : null}
          <Row>
            <Col md={15} className="text-end">
              <Button
                label="Save & Next"
                type="submit"
                onClick={() => {
                  // checkNavigate();
                }}
                // disabled={isChecked}
              ></Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Row>
        <ValidationErrorModal
          showValidationModal={showValidationModal}
          onHide={() => setShowValidationModal(false)}
          error={validationError}
        />
      </Row>
    </>
  );
};

export default OpsApplicationUndertaking;
